import React from 'react';
import "./inside.style.css";

function Inside() {
  return (
    <section className="section-inside" id="inside">
      <div className="inside container">
        <h2 className="heading-seconday inside-heading">What's <span>inside?</span></h2>

        <div className="inside-content grid grid-gap  grid--center-vertical">
          <div className="inside-img">
            <img src="assets/img/kindle.png" alt="Book cover" className="author-img" />
          </div>
          <div className="table-of-contents">
            <ul className="chapter-list">
              <li>Introduction</li>
            </ul>

            <ul className="chapter-list">
              <li>Chapter 1: Laying the Foundation of Health
                <ul className="sub-chapter">
                  <li>1.1 Understanding Your Body</li>
                  <li>1.2 Setting Health Goals</li>
                </ul>
              </li>
            </ul>

            <ul className="chapter-list">
              <li>Chapter 2: Nourishment for Wellness
                <ul className="sub-chapter">
                  <li>2.1 Embracing Balanced Eating Habits</li>
                  <li>2.2 Exploring Nutrient-Rich Foods</li>
                  <li>2.3 Practicing Mindful Eating</li>
                </ul>
              </li>
            </ul>

            <ul className="chapter-list">
              <li>Chapter 3: The Power of Physical Activity
                <ul className="sub-chapter">
                  <li>3.1 Building an Active Lifestyle</li>
                  <li>3.2 Effective Workout Strategies</li>
                  <li>1.3: Overview</li>
                </ul>
              </li>
            </ul>

            <ul className="chapter-list">
              <li>Chapter 4: Rest and Recovery
                <ul className="sub-chapter">
                  <li>4.1 The Importance of Quality Sleep</li>
                  <li>4.2 Stress Management Techniques</li>
                </ul>
              </li>
            </ul>

            <ul className="chapter-list">
              <li>Chapter 5: Mind and Body Connection
                <ul className="sub-chapter">
                  <li>5.1 Embracing Mindfulness and Meditation</li>
                  <li>5.2 Nurturing Emotional Well-being</li>
                </ul>
              </li>
            </ul>

            <ul className="chapter-list">
              <li>Chapter 6: Social and Environmental Wellness
                <ul className="sub-chapter">
                  <li>6.1 Nurturing Healthy Relationships</li>
                  <li>6.2 Sustainable Living Practices</li>
                </ul>
              </li>
            </ul>

            <ul className="chapter-list">
              <li>Chapter 7: Sustaining Your Healthy Habits
                <ul className="sub-chapter">
                  <li>7.1 Overcoming Challenges</li>
                  <li>7.2 Staying Motivated</li>
                </ul>
              </li>
            </ul>
            <ul className="chapter-list">
              <li>Conclusion</li>
            </ul>

          </div>
        </div>

      </div>

    </section>
  );
}

export default Inside;
