import "./footer.style.css";

function Footer() {

  return (
    <footer className="section-footer">
      <div className="container grid grid--footer">
        <div className="logo-col">
          <h2 className="logo"><a href="/">Book<span>Launchify</span></a></h2>
          <p className="footer-about">Veniam Sequi molestias aut necessitatibus optio magni at natus accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>

          <ul className="social-links">
            <li>
              <a className="footer-link" href="https://www.example.com"><i className="fa-brands fa-instagram"></i></a>

            </li>
            <li>
              <a className="footer-link" href="https://www.example.com"><i className="fa-brands fa-square-facebook"></i></a>
            </li>
            <li>
              <a className="footer-link" href="https://www.example.com"><i className="fa-brands fa-square-twitter"></i></a>
            </li>
          </ul>


        </div>
        <div className="address-col">
          <p className="footer-heading">Contact us</p>
          <address className="contacts">
            <p className="address">
              623 Harrison St., 2nd Floor, San Francisco, CA 94107
            </p>
            <p>
              <a className="footer-link" href="tel:415-201-6370">415-201-6370</a><br />
              <a className="footer-link" href="mailto:hello@omnifood.com">hello@example.com</a>
            </p>
          </address>
        </div>
        <nav className="nav-col">
          <p className="footer-heading">Account</p>
          <ul className="footer-nav">
            <li><a className="footer-link" href="https://www.example.com">Create account</a></li>
            <li><a className="footer-link" href="https://www.example.com">Sign in</a></li>
            <li><a className="footer-link" href="https://www.example.com">iOS app</a></li>
            <li><a className="footer-link" href="https://www.example.com">Android app</a></li>
          </ul>
        </nav>

        <nav className="nav-col">
          <p className="footer-heading">Company</p>
          <ul className="footer-nav">
            <li><a className="footer-link" href="https://www.example.com">About Author</a></li>
            <li><a className="footer-link" href="https://www.example.com">For Business</a></li>
            <li><a className="footer-link" href="https://www.example.com">Publishing  partners</a></li>
            <li><a className="footer-link" href="https://www.example.com">Careers</a></li>
          </ul>
        </nav>

      </div>
    </footer>
  )
}

export default Footer;