const menus = [
  {
    id: 1,
    tomenu: "#home",
    namemenu: "Home",
  },
  {
    id: 2,
    tomenu: "#about",
    namemenu: "About",
  },
  {
    id: 3,
    tomenu: "#inside",
    namemenu: "Inside",
  },
  {
    id: 4,
    tomenu: "#testimonials",
    namemenu: "Testimonials",
  },
  {
    id: 5,
    tomenu: "#pricing",
    namemenu: "Pricing",
  },

  {
    id: 6,
    tomenu: "#contact",
    namemenu: "Contact",
  },
];

export default menus;
