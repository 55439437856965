import "./whom.style.css";

function Whom() {
  return (<section className="section-whom">
    <div className="container">
      <h2 className="heading-seconday center">Who This Book Is For</h2>
      <p className="whom-subheading center">This book is concerned with creating typography and is essential for professionals who regularly work for clients.</p>

      <div className="target-audience">
        <div className="audience">
          <i className="fa-regular fa-id-badge"></i>
          <h4 className="heading-quaternary">Entrepreneurs</h4>
          <p>Behind the word mountains, far from the countries</p>
        </div>
        <div className="audience">
          <i className="fa-regular fa-circle-user"></i>
          <h4 className="heading-quaternary">Students</h4>
          <p>Behind the word mountains, far from the countries</p>
        </div>
        <div className="audience">
          <i className="fa-regular fa-star"></i>
          <h4 className="heading-quaternary">Professionals</h4>
          <p>Behind the word mountains, far from the countries</p>
        </div>


      </div>
    </div>

  </section>)
}

export default Whom;