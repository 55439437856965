import React, { useState, useEffect } from 'react';
import './scroll-to-top.style.css';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <i
      onClick={handleScrollToTop}
      className={`scroll-to-top-button fa-solid fa-circle-chevron-up ${isVisible ? 'show' : ''}`}
    ></i>
  );
};

export default ScrollToTopButton;
