import React, { useState } from 'react';
import testimonialData from "../../../../data/testimonials";
import LeftArrowIcon from "../../../../data/arrow/left-arrow-icon";
import RightArrowIcon from "../../../../data/arrow/right-arrow-icon";
import "./testimonials.style.css";

function Testimonials() {


  const [activeIndex, setActiveIndex] = useState(0);

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % testimonialData.length);
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? testimonialData.length - 1 : prevIndex - 1
    );
  };

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <section className="section-testimonials" id="testimonials">

      <div className="carousel container">


        <blockquote className="testimonial">
          <p className="testimonial-text">
            {testimonialData[activeIndex].text}
          </p>
          <p className="testimonial-author">{testimonialData[activeIndex].author}</p>
          <p className="testimonial-job">{testimonialData[activeIndex].job}</p>
        </blockquote>
        <img src={testimonialData[activeIndex].image} alt={testimonialData[activeIndex].author} />


        <button className="testimonial-btn btn--left" onClick={handlePrev}>
          <LeftArrowIcon />
        </button>
        <button className="testimonial-btn btn--right" onClick={handleNext}>
          <RightArrowIcon />
        </button>
        <div className="dots">
          {testimonialData.map((_, index) => (
            <button
              key={index}
              className={`dot ${index === activeIndex ? 'dot--fill' : ''}`}
              onClick={() => handleDotClick(index)}
            >
              &nbsp;
            </button>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Testimonials;