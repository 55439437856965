import React, { useState } from 'react';
import accordionData from "../../../../data/faq";
import "./faq.style.css"

function Faq() {


  const [isOpen, setIsOpen] = useState(Array(accordionData.length).fill(false));

  const toggleAccordion = (index) => {
    const updatedOpenStates = [...isOpen];
    updatedOpenStates[index] = !updatedOpenStates[index];
    setIsOpen(updatedOpenStates);
  };

  return (
    <section className="section-faq">
      <div className="accordion container">
        <h2 className="heading-seconday center">Any questions?</h2>
        <p className="center subheading">
          This book is concerned with creating typography and is essential for professionals who regularly work for clients.
        </p>

        <div className="faq-list">
          <div className="faq-list-column">
            {accordionData.slice(0, Math.ceil(accordionData.length / 2)).map((item, index) => (
              <div className={`item ${isOpen[index] ? 'open' : ''}`} key={index}>
                <p className="number">{index * 2 + 1}</p> {/* Calculate the number */}
                <p className="text">{item.question}</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`icon ${isOpen[index] ? 'open' : ''}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  onClick={() => toggleAccordion(index)}
                  role="button"
                  tabIndex={0}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
                <div className={`hidden-box ${isOpen[index] ? 'open' : ''}`}>
                  <p>{item.answer}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="faq-list-column">
            {accordionData.slice(Math.ceil(accordionData.length / 2)).map((item, index) => (
              <div className={`item ${isOpen[index + Math.ceil(accordionData.length / 2)] ? 'open' : ''}`} key={index}>
                <p className="number">{index * 2 + 2}</p> {/* Calculate the number */}
                <p className="text">{item.question}</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`icon ${isOpen[index + Math.ceil(accordionData.length / 2)] ? 'open' : ''}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  onClick={() => toggleAccordion(index + Math.ceil(accordionData.length / 2))}
                  role="button"
                  tabIndex={0}

                >


                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>

                <div className={`hidden-box ${isOpen[index + Math.ceil(accordionData.length / 2)] ? 'open' : ''}`}>
                  <p>{item.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq;
