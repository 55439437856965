import React from "react";
import "./firstChapter.style.css";

function FirstChapter({ setFirstChapterOpen }) {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setFirstChapterOpen(false);
            }}
          >
            X
          </button>
        </div>

        <div className="modal">
          <div className="modal-text-box">
            <h2 className="heading-secondary">Get started your long living journey with this First Chapter!</h2>
            <p className="modal-text">
              Healthy, tasty and hassle-free life is waiting for you. Start
              living well today. You can cancel or pause anytime. And the
              first chapter is on us!
            </p>

            <form className="modal-form" action="#">
              <div>
                <label htmlFor="full-name">Full Name</label>
                <input id="full-name" type="text" placeholder="John Smith" required />
              </div>

              <div>
                <label htmlFor="email">Email address</label>
                <input id="email" type="email" placeholder="me@example.com" required />
              </div>

              <div>
                <label htmlFor="select-where">Where did you hear from us?</label>
                <select id="select-where" required>
                  <option value="">Please choose one option:</option>
                  <option value="friends">Friends and family</option>
                  <option value="youtube">YouTube video</option>
                  <option value="podcast">Podcast</option>
                  <option value="ad">Facebook ad</option>
                  <option value="others">Others</option>
                </select>
              </div>

              <button className="btn btn--form">Get First Chapter</button>
            </form>
          </div>

        </div>
      </div>
    </div>
  );
}

export default FirstChapter;