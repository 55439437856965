import "./contact.style.css";
function Contact() {
  return (
    <section className="section-contact" id="contact">
      <div className="container">
        <div className="contact">
          <div className="contact-text-box">
            <h2 className="heading-seconday heading-contact">Contact with Author</h2>
            <p className="center subheading">
              This book is concerned with creating typography and is essential for professionals who regularly work for clients.
            </p>

            <form action="#">

              <div className="contact-form">
                <div className="mobile-contact">
                  <input id="name" type="text" placeholder="Your Name" required />
                </div>
                <div className="mobile-contact">
                  <input id="email" type="email" placeholder="me@example.com" required />
                </div>

                <div className="full-width">

                  <textarea className="myTextarea" name="myTextarea" rows="10" defaultValue="Your message goes here." />

                </div>
                <div className="full-width center">
                  <button className="btn btn--form">Get The First Chapter</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact;