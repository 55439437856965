const testimonialData = [
  {
    text: "This book is a life-changer. With clear guidance and motivating insights, it's your path to a healthier, happier life. Highly recommended!",
    author: "Maria de Almeida",
    job: "Senior Product Manager at EDP Comercial",
    image: "assets/img/maria.jpg",
  },
  {
    text: "I can't recommend this book enough. It's like having a personal wellness coach in your hands. The practical tips and insights have truly empowered me to live a healthier life.",
    author: "John Doe",
    job: "CEO at LTN Company",
    image: "assets/img/michael.jpg",
  },
  {
    text: "I've read many health books, but this one stood out. It's filled with practical advice, easy-to-follow recipes, and mindful exercises. It's become my go-to guide for a healthier lifestyle.",
    author: "Sarah Smith",
    job: "Marketing Manager at MK Corp",
    image: "assets/img/sarah.jpg",
  },
  {
    text: "As a busy professional, I struggled with finding balance in my life. This book showed me how to prioritize self-care without sacrificing my career. It's a must-read for anyone seeking a healthier work-life balance",
    author: "Michael Johnson",
    job: "Software Engineer at TechCo",
    image: "assets/img/john.jpg",
  },
];

export default testimonialData;
