import React, { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import "./navigation.style.css";
import FirstChapter from "../modal/first-chapter";
import "./navigation.style.css";
import menus from "../../../../data/menus";

const Navigation = () => {
  const [firstChapterOpen, setFirstChapterOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuIcon, setMobileMenuIcon] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const toggleMobileMenu = () => {
    setMobileMenuIcon(!mobileMenuIcon);
    setMobileMenu(!mobileMenu);
  };

  return (
    <div
      id="navigation-container"
      className={`section-header back ${mobileMenu ? "nav-open" : ""} ${
        scrolled ? "scrolled" : ""
      }`}
    >
      <h2 className="logo">
        <a href="/">
          Book<span>Launchify</span>
        </a>
      </h2>
      <nav className="main-nav">
        <ul className="main-nav-list">
          {menus.map((menu) => (
            <li key={menu.id}>
              <HashLink
                to={menu.tomenu}
                className="main-nav-link js-scroll-trigger"
              >
                {menu.namemenu}
              </HashLink>
            </li>
          ))}

          <li>
            <button
              onClick={() => {
                setFirstChapterOpen(true);
              }}
              className="first-chapter-btn"
              href="/"
            >
              Get it
            </button>
          </li>
        </ul>
      </nav>

      <div className="btn-mobile-nav" onClick={toggleMobileMenu}>
        <i
          className="fa-solid fa-bars icon-mobile-nav"
          name={mobileMenuIcon ? "menu-outline" : "close-outline"}
        ></i>
        <i
          className="fa-regular fa-rectangle-xmark icon-mobile-nav"
          name={mobileMenuIcon ? "close-outline" : "menu-outline"}
        ></i>
      </div>

      {firstChapterOpen && (
        <FirstChapter setFirstChapterOpen={setFirstChapterOpen} />
      )}
    </div>
  );
};

export default Navigation;
