import React from 'react';
import "./why.style.css";

function WhyThisBook() {
  return (
    <section className="section-why" id="about">
      <div className="container why-this-book">
        <h2 className="heading-seconday">Why this book?</h2>

        <div className="why-content grid grid-gap grid--2--cols">
          <div className="author-img-box">

            <img src="assets/img/author.jpeg" alt="Author" className="author-img" />
          </div>

          <div className="about-book-author">
            <p className="book-description">
              I am the author, deeply committed to a life of fitness and wellness. "Why This Book?" offers a glimpse
              into my personal journey, fueled by years of research and practical application.
              <br /><br />
              My motivation in writing this book is simple: I understand the challenges of adopting a healthier
              lifestyle, and I want to help you overcome them. Through my own experiences and the lessons I've
              learned.
              <br /><br />
              I hope to inspire and guide you on your path to better health. "Healthy Habits for Fit Living" is more
              than just a book—it's a transformative tool to support you in achieving lasting wellness.
            </p>

            <p className="author-location">Los Angeles, United States</p>

            <div className="social-icons">
              <i className="fa-brands fa-square-facebook"></i>
              <i className="fa-brands fa-square-twitter"></i>
              <i className="fa-brands fa-linkedin"></i>
            </div>
          </div>
        </div>
      </div>

      <div className="container why-this-audiobook">
        <h2 className="heading-seconday">Why this Audiobook?</h2>

        <div className="why-content grid grid-gap grid--2--cols">
          <div className="about-book-author">
            <p className="book-description">
              In an increasingly fast-paced world, I recognize the importance of accessibility and convenience. While
              "Healthy Habits for Fit Living" is available in print and digital formats, this audiobook offers a unique
              advantage. It allows you to seamlessly integrate healthy habits into your daily routine. Whether you're
              commuting, exercising, or simply relaxing, you can absorb valuable insights and inspiration with ease.
              <br /><br />
              This audiobook brings my journey and insights to life through the power of spoken word, offering a
              dynamic and engaging way to embark on your own path to better health.
            </p>

            <div className="social-icons">
              <audio controls>
                <source src="https://www.coothead.co.uk/audio/You-Cant-Always-Get-What-You-Want.mp3" type="audio/mpeg" />
              </audio>
            </div>
          </div>
          <div className="author-img-box">
            <img src="assets/img/audio-recording.jpeg" alt="Audio Recording" className="author-img" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyThisBook;
