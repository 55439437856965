import React, { useEffect, useState } from "react";
import FirstChapter from "../modal/first-chapter";
import "./hero.style.css";

function Hero({ openModal }) {
  const [firstChapterOpen, setFirstChapterOpen] = useState(false);
  useEffect(() => {
    document.addEventListener("DOMContentLoaded", function () {
      const links = document.querySelectorAll(".main-nav-link");

      links.forEach((link) => {
        link.addEventListener("click", (e) => {
          e.preventDefault();

          const targetId = link.getAttribute("href").substring(1);
          const targetElement = document.getElementById(targetId);

          if (targetElement) {
            window.scrollTo({
              top: targetElement.offsetTop,
              behavior: "smooth",
            });
          }
        });
      });
    });
  }, []);

  return (
    <section className="section-hero">
      <div className="hero grid--2--cols">
        <div className="hero-text-box">
          <h1 className="heading-primary">7 Healthy ways for Fit Living</h1>
          <p className="hero-description">
            Healthy Ways for Fit Living" is your roadmap to a vibrant life. Discover simple, sustainable practices
            that boost your physical and mental well-being. From nutritious eating to invigorating exercise routines,
            this book empowers you to take charge of your health.<br /><br />

            Make lasting changes and transform your lifestyle with
            practical guidance, inspiring stories, and proven strategies for a life filled with vitality and balance.
          </p>
          <a href="www.facebook.com" className="btn btn--full margin-right-sm">Start Living well</a>
          <a href="www.facebook.com" className="btn btn--outline">Audiobook Version &darr;</a>

          <div className="free-download">
            <p className="download-text">Or <a onClick={openModal}
              href="#hero-section">download</a> the first chapter for free.</p>
          </div>


        </div>

        <div className="hero-img-box">
          <div className="book-box">
            <div className="book">
              <div className="title">
                <p>GET IT ON AMAZON</p>
              </div>
              <div className="book-cover">
                <div className="effect"></div>
                <div className="light"></div>
              </div>
              <div className="book-inside">
              </div>
              <a className="book-btn" href="www.facebook.com">£19.95</a>
            </div>
          </div>
        </div>
      </div>

      {firstChapterOpen && (
        <FirstChapter setFirstChapterOpen={setFirstChapterOpen} />
      )}
    </section>

  );
}

export default Hero;