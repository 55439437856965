const accordionData = [
  {
    question: "What is the title of the health book?",
    answer:
      "The book is titled 'Living a Healthy Life: A Guide to Wellness and Well-being.'",
  },
  {
    question: "Who is the author of the book?",
    answer:
      "The book is authored by Dr. Emily Johnson, a renowned medical expert with over 20 years of experience.",
  },
  {
    question: "What topics are covered in the book?",
    answer:
      "The book covers a wide range of health topics, including nutrition, exercise, stress management, disease prevention, and more.",
  },
  {
    question: "Is this book for beginners in health?",
    answer:
      "Yes, this book is designed for readers of all levels. It offers both basic information for beginners and advanced insights for those with prior knowledge.",
  },
  {
    question: "Are there practical tips for a lifestyle?",
    answer:
      "Absolutely! The book is filled with practical tips, real-life examples, and actionable advice to help you maintain a healthy lifestyle.",
  },
  {
    question: "Can I find meal recipes in the book?",
    answer:
      "Yes, the book includes meal plans, delicious recipes, and guidance on creating a balanced diet for improved health.",
  },
  {
    question: "Does the book address mental health?",
    answer:
      "Yes, it does. There is a dedicated section on managing stress and improving mental well-being.",
  },
  {
    question: "Are there any success stories",
    answer:
      "You'll find inspiring success stories and case studies throughout the book to motivate and guide you on your health journey.",
  },
  {
    question: "How can I purchase the book?",
    answer:
      "You can purchase the book through our website, and it's also available on major online retailers such as Amazon and Barnes & Noble.",
  },
  {
    question: "Is there an e-book available for readers?",
    answer:
      "Yes, an e-book version is available for those who prefer digital reading. You can download it from various e-book platforms.",
  },
  {
    question: "What formats are available?",
    answer:
      "The book is available in both hardcover and paperback formats. An audiobook version is also in production and will be released soon.",
  },
  {
    question: "Is there a money-back with the book?",
    answer:
      "Yes, we offer a 30-day money-back guarantee. If you're not satisfied with the book, you can return it within 30 days of purchase for a full refund.",
  },
];

export default accordionData;
