import "./pricing.style.css";
function PricingTable() {
  return (
    <section className="section-pricing" id="pricing">
      <div className="container">
        <div className="pricing-heading">
          <h2 className="heading-seconday">Get it now</h2>
          <p className="center subheading">
            This book is concerned with creating typography and is essential for professionals who regularly work for clients.
          </p>
        </div>

        <div className="pricing-table">
          <div className="pricing-card audiobook-pricing">
            <h4>Audiobook</h4>
            <p className="price">$14<span>.95</span></p>

            <div className="feature-and-button">
              <ul>
                <li><i className="fa-solid fa-check"></i> 3+ Hours</li>
                <li><i className="fa-solid fa-check"></i> Bonus Content</li>
                <li><i className="fa-solid fa-check"></i> MP3 format</li>
              </ul>
              <div className="pricing-buttons">
                <a href="www.facebook.com" className="btn amazon-button">Buy On Audible</a>
                <a href="www.facebook.com" className="btn audiobook-button">Buy on Gumroad</a>
              </div>
            </div>
          </div>

          <div className="pricing-card ebook-pricing">
            <h4 className="ebook-title">E-Book</h4>
            <p className="price">$9<span>.94</span></p>

            <div className="feature-and-button">
              <ul>
                <li><i className="fa-solid fa-check"></i> 120+ Pages</li>
                <li><i className="fa-solid fa-check"></i> 14 Interviews</li>
                <li><i className="fa-solid fa-check"></i> MOBI format</li>
                <li><i className="fa-solid fa-check"></i> ePUB format </li>
              </ul>
              <div className="pricing-buttons">
                <a href="www.facebook.com" className="btn amazon-button">Buy On Audible</a>
                <a href="www.facebook.com" className="btn audiobook-button">Buy on Gumroad</a>
              </div>
            </div>
            <p className="popular">Most popular</p>
          </div>

          <div className="pricing-card hardcover-pricing">
            <h4>Hardcover</h4>
            <p className="price">$19<span>.95</span></p>

            <div className="feature-and-button">
              <ul>
                <li><i className="fa-solid fa-check"></i> 3+ Hours</li>
                <li><i className="fa-solid fa-check"></i> Bonus Content</li>
                <li><i className="fa-solid fa-check"></i> MP3 format</li>
              </ul>
              <div className="pricing-buttons">
                <a href="www.facebook.com" className="btn amazon-button">Buy On Audible</a>
                <a href="www.facebook.com" className="btn audiobook-button">Buy on Gumroad</a>
              </div>
            </div>
          </div>
        </div>
        <p className="pricing-footer">More Than <span>5464+</span> copies sold!</p>
      </div>
    </section>

  )
}

export default PricingTable;