import {
  Navigation,
  WhyThisBook,
  Inside,
  Testimonials,
  PricingTable,
  Faq,
  Footer,
  Contact,
  Whom,
  ModalContainer,
  ScrollToTopButton,
} from "../../layouts/homeLtr/index";

function Home() {
  return (
    <main>
      <Navigation />
      <ModalContainer />
      <WhyThisBook />
      <Inside />
      <Whom />
      <Testimonials />
      <PricingTable />
      <Faq />
      <Contact />
      <Footer />
      <ScrollToTopButton />
    </main>
  );
}

export default Home;
